export const RulesData = [
    {
        id: 1,
        rule: "Identity Card is compulsory for getting access to the library."
    },
    {
        id: 2,
        rule: "Every Student of ALC is entitled to Borrow Three library books for 7 days."
    },
    {
        id: 3,
        rule: "Student should not write, mark or underline anywhere in the book."
    },
    {
        id: 4,
        rule: "Silence to be maintained ."
    },
    {
        id: 5,
        rule: "No discussion permitted inside the library."
    },
    {
        id: 6,
        rule: "Registration should be done to become a library member prior to using the library resources."
    },
    {
        id: 7,
        rule: "Textbooks, printed materials and issued books are not allowed to be taken inside the library. "
    },
    {
        id: 8,
        rule: "Using Mobile phones and audio instruments with or without speaker or headphone is strictly prohibited in the library premises. "
    },
    {
        id: 9,
        rule: "Enter your name and Sign in the register kept at the entrance counter before entering library."
    },
    {
        id: 10,
        rule: "Show the books and other materials which are being taken out of the library to the staff at the entrance counter.  "
    },
    {
        id: 11,
        rule: "The librarian may recall any book from any member at any time and the member shall return the same immediately. "
    },
    {
        id: 12,
        rule: "Library borrower cards are not transferable. The borrower is responsible for the books borrowed on his/her card. "
    },
]

export const RenewalData = [
    {
        id: 1,
        rule: "Books are renewed on the due date only.  "
    },
    {
        id: 2,
        rule: "Books can be renewed in person twice for 15 days unless they are reserved.Book Lost/ Loss of cards. "
    },
    {
        id: 3,
        rule: " If the books are lost, then the borrower shall replace the books of the same edition or latest edition or pay double cost of the book after getting permission from the librarian."
    },
    {
        id: 4,
        rule: " Loss of borrower card should be reported to the librarian."
    },
    {
        id: 5,
        rule: "Duplicate card may be issued against formal application and fine."
    },
]

export const BookReturn = [
    {
        id: 1,
        type: "BBA. LL.B",
        book: "03",
        duration: "07 Days"
    }
]

export const FacultyData = [
    {
        id: 1,
        type: "TEACHING (FACULTY)",
        book: "15",
        duration: "180 Days",
        refbook: "05",
        duration2: "30 Days"
    },
    {
        id: 2,
        type: "NON- TEACHING (ADMINISTRATIVE STAFF)",
        book: "03",
        duration: "30 Days",
        refbook: "01",
        duration2: "30 Days"
    }
]

export const servicesData = [
    {
        id: 1,
        title: "Circulation",
        description: "Books will be issued on presentation of the ID card. Borrowers are instructed to check the books while borrowing and they will be responsible for any type of damage or mutilation noticed at the time of return. The library adheres to the Open Access principle. You can choose a book from the shelves and either borrow it or use it at the library.",
    },
    {
        id: 2,
        title: 'OPAC Service',
        description: 'A public online bibliography of a library\'s holdings is known as an OPAC (Online Public Access Catalogue). Where you can locate your book or other materials online.'
    },
    {
        id: 3,
        title: 'Internet Wi-Fi Facility',
        description: 'Ten internet-connected computers are available at the library. The entire Takshashila library has Wi-Fi and an internet connection.'
    },
    {
        id: 4,
        title: 'References',
        description: 'Encyclopaedias, Yearbooks, the Manual SCC, Labour law reports, AIR reports, and other reference works are well-represented in the library\'s collection. The dictionaries are not lendable.'
    },
    {
        id: 5,
        title: 'Email Alerts',
        description: 'Users who use the library services they receive alerts through email and text message regarding transactions there.'
    },
    {
        id: 6,
        title: 'Photocopy Services',
        description: 'The library offers photocopying from 3:00 AM to 5:00 PM. The copyright policy is supported by the library.'
    },
    {
        id: 7,
        title: 'Current Awareness Services',
        description: 'Library provides alert service via Email or Instant Messaging app regarding New arrivals of Books/Periodicals or Latest News Regarding Law.'
    },
    {
        id: 8,
        title: 'Book Lost',
        description: 'If the books are lost, then the borrower shall replace the books of the same edition or latest edition or pay double cost of the book after getting permission from the librarian.'
    },
    {
        id: 9,
        title: 'Validity of Cards',
        description: 'Library borrower cards are valid for the entire duration of the course to access library facilities at the end of the course borrower cards shall be returned to the library.'
    },
    {
        id: 10,
        title: 'Care of Library Books',
        description: 'Students are requiring to handle the books/ Journal very carefully; marking with pencil, writing or highlighting, tearing the pages or mutilating the same in any other way will be viewed very seriously. In such case reader shall be held responsible unless these are brought to the notice of the library staff at the time of issue.'
    },
    {
        id: 11,
        title: 'Journal Section',
        description: 'In these section journals, magazines and newsletter are available. The latest issues are displayed on display rack and other previous issues are arranged in the drawer. Bound volumes of periodicals are arranged in rack with chronological order and are meant only for reference within library..'
    },
]


export const AdvisoryCommitee = [
    {
        id: 1,
        name: 'Dr. Madhushree Joshi',
        designation: 'Principal',
        post: "Chairperson"
    },
    {
        id: 2,
        name: 'Col. A. K. Pandey (Retd) ',
        designation: 'Registrar & Head of Administration',
        post: "Member"
    },
    {
        id: 3,
        name: 'Dr. Gajanan Patil ',
        designation: 'Assistant Professor ',
        post: "Member"
    },
    {
        id: 4,
        name: 'Dr. Ajit Borde ',
        designation: 'Assistant Professor ',
        post: "Member"
    },
    {
        id: 5,
        name: 'Dr. Ajay Tambulkar  ',
        designation: 'Assistant Professor',
        post: "Member"
    },
    {
        id: 6,
        name: 'Mr. Ganeshappa Deshmane ',
        designation: 'Assistant Professor ',
        post: "Member"
    },
    {
        id: 7,
        name: 'Mr. Ankit Kumar ',
        designation: 'Students Representative',
        post: "Member"
    },
    {
        id: 8,
        name: 'Ms. Garima Meena ',
        designation: 'Students Representative',
        post: "Member"
    },
    {
        id: 9,
        name: 'Mr. Prashant Bharat Vanshiv ',
        designation: 'Librarian',
        post: "Secretary"
    },
]