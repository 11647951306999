import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars, faTimes, } from '@fortawesome/free-solid-svg-icons';

export default function WebResources() {
    const LabData = [
        {
            id: 1.1,
            name: "Total Books",
            quantity: 7050
        },
        {
            id: 1.2,
            name: "Reference Books",
            quantity: 869
        },
        {
            id: 1.3,
            name: "Total Tiles",
            quantity:2467
        },
        {
            id: 1.4,
            name: "Journals /Periodicals",
            quantity: 36
        },
        {
            id: 1.5,
            name: "Newspapers",
            quantity:"07"
        },
        {
            id: 1.6,
            name: "E- Resources (Online Database)",
            quantity:"05"
        },
    ]


    const LabData2 = [
        {
            id: 1.1,
            name: "A.I.R. Online",
        },
        {
            id: 1.2,
            name: "Manupatra"
        },
        {
            id: 1.3,
            name: "N - LIST",
        },
        {
            id: 1.4,
            name: "SCC with Hein Online Platinum"
        },
        {
            id: 1.5,
            name: "LexisNexis",
        }
    ]
    return (
        <div>
                    <h2 className='progsubhead'>Print and Web Resources</h2>
                    <div className='tables mt-5'>
                        <table className="Lcustom-table col-12 ">
                            <tbody>
                                {
                                    LabData.map((data, i) => (
                                        <tr key={i}>
                                            <td className="col-2">{data.id}</td>
                                            <td className="col-8">{data.name}</td>
                                            <td className="col-2">{data.quantity}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='tables mt-5'>
                        <table className="Lcustom-table col-12 ">
                            <tbody>
                            <tr className='lthead'>
                                <td className="col-2">Sr No</td>
                                <td className="col-10">Names of the E- Journals/Online Databases</td>
                            </tr>
                                {
                                    LabData2.map((data, i) => (
                                        <tr key={i}>
                                            <td className="col-2">{data.id}</td>
                                            <td className="col-10">{data.name}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
    )
}
